<template>
  <v-container fluid>
    <!--    <Dashboard :company-id="companyId" />-->
    <div>company {{ companyId }}</div>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'CompanyDashboard',

  components: {
    // Dashboard: () => import('@/components/common/Dashboard'),
  },

  computed: {
    companyId() {
      return this.$route.params.id
    },
  },

  mounted() {
    this.initialize()
  },

  methods: {
    ...mapMutations('companies', ['SET_FILTER_LOADING_STATUS']),

    initialize() {
      this.SET_FILTER_LOADING_STATUS(false)
    },
  },
}
</script>
